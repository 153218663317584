import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Container, Row, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import { API_ROOT } from "gatsby-env-variables";
import { Link } from "gatsby";

class jobDetailPage extends React.Component {
  state = {
    body: "",
    jobTitle: "",
  };
  componentDidMount() {
    if (window.$crisp && window.$crisp !== undefined) {
      window.$crisp.push(["do", "chat:hide"]);
    }

    var id = this.props["*"];
    this.getJobDetail(id);
  }
  getJobDetail(id) {
    axios.get(API_ROOT + `/api/v1/jobs/${id}`).then((response) => {
      var res = response?.data?.data;
      this.setState({
        body: res.body,
        jobTitle: res.title,
        metaDescription: res.meta_description,
        metaKeyword: res.meta_keyword,
        metaTitle: res.meta_title,
      });
    });
  }
  render() {
    return (
      <Layout pageInfo={{ pageName: "Job Detail" }}>
        <SEO
          title={this.state.metaTitle}
          keywords={[this.state.metaKeyword]}
          description={[this.state.metaDescription]}
        />
        <Container fluid className={"inline-pages"}>
          <Row className={"text-center"}>
            <Col className={"inline-pages-title"} md={{ span: 10, offset: 1 }}>
              <p>Job</p>
            </Col>
            <Col
              className={"inline-pages-border"}
              md={{ span: 10, offset: 1 }}
            ></Col>
            <Col md={{ span: 10, offset: 1 }} className={"p0"}>
              <p className={"bread-crumb"}>
                <Link to="/" className="link-no-style">
                  &nbsp;Home /&nbsp;
                </Link>
                <Link to="/jobs" className="link-no-style">
                  &nbsp;Jobs /&nbsp;
                </Link>
                {this.state.jobTitle}
              </p>
            </Col>
          </Row>
          <Row>
            <Col
              className={"inline-pages-content-box"}
              md={{ span: 10, offset: 1 }}
            >
              <Col className={"job-detail-header"} md={"12"}>
                <p className={"job-detail-title"}>{this.state.jobTitle}</p>
              </Col>
              <Col className={"mt20 p-0"} md={"12"}>
                <p className={"job-detail-description"}>
                  {ReactHtmlParser(this.state.body)}
                </p>
              </Col>
              <Col className={"mt20 text-center"} md={"12"}>
                <button className={"submit-btn"}>
                  <a
                    href="mailto:info@risklick.ch?subject=job application"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply
                  </a>
                </button>
              </Col>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default jobDetailPage;
